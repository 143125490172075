<template>
<div>
	<section id='pricing-plans1'>
    	<h1>Choose the plan that fits you</h1>
    	<p class="des d-none d-md-block" >If you are unsure which pricing plan to choose, then register for a free trial to see how Muctasar features can help you to get the best of reading</p>
    	<div class="cards">
			<div class="row justify-content-center">
				<div class="col-12 col-md-auto" v-for="(plan,n) of plans" :key="n">
					<plan-card :plan='plan' :filpCard='true' @handleClick='openCardPage'/>
				</div>
			</div>
    	</div> 
	</section>
	<section id='premium-features'>
    	<h1>Premium features</h1>
		<div class="container">
			<div class="row">
				<div class="col-1"></div>
				<div class="col-10">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="row feature align-items-center">
								<div class="order-2 order-md-1 col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature1.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Unlimited access to every title
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="feature row align-items-center">
								<div class="order-2 order-md-1 col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature2.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Learn hands free with audio
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="feature row align-items-center">
								<div class="order-2 order-md-1 col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature3.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Highlight ideas you love
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="feature row align-items-center">
								<div class="order-2 order-md-1  col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature4.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Enjoy your library offline
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="feature row align-items-center">
								<div class="order-2 order-md-1  col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature5.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Sync highlights with Evernote
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="feature row align-items-center">
								<div class="order-2 order-md-1  col-3 col-md-12 feature-icon">
									<img src='assets/images/features/p-feature6.svg'/>
								</div>
								<div class="order-1 order-md-2 col-9 col-md-12 feature-des">
									Send your reads to Kindle
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-1"></div>
			</div>
		</div>
	</section> 
</div>
</template>
<script>
import PlanCard from '@/components/ui/PlanCard2';
export default {
	components: {
		PlanCard
	},
	data() {
		return {
			plans: [
				{
					type: 'الباقة السنوية',
					price: 14,
					des:'تُدفع مرة واحدة في السنة ويمكنك الإلغاء في أي وقت',
					active: true
				},
				{
					type: 'الباقة الشهرية',
					price: 14,
					des:'تُدفع مرة واحدة في الشهر ويمكنك الإلغاء في أي وقت',
					active: false

				}
			]
		}
	},
	methods: {
		openCardPage() {
			this.$router.push({ path: 'card-page' })
		}
	},
	mounted() {
		//  var windowH = $(window).height() - 65 ;
		//  $('#pricing-plans').height(windowH)
	}
}
</script>
<style lang='scss' scoped>
.cards {
	max-width: 90%;
	margin: auto;
}
#pricing-plans1 {
	padding-bottom: 150px;
	@media only screen and (max-width: 769px) {
		padding-bottom: 20px;
	}
}


</style>