<template>
	<div class="price-card" :class="{ active: plan.active ,'flip':filpCard}" >
		<div class="bookmarkRibbon" v-if="plan.active">
			<span>
				الباقة الأكثر شراءً
			</span>
		</div>
		<!-- <div class="aside" v-if="plan.active">الباقة الأكثر شراءً</div>  -->
		<div>
			<div class="price-info">
				<div class="price-period">{{plan.type}}</div>
				<div class="price-count">
					<span class="dolar-sign">ر.س</span><span class="price">{{plan.price}}</span>/ per month
				</div> 
			</div>
			<p>
				<!-- <span v-if="plan.active">Plus 7 days free trial</span> -->
				{{plan.des}}
			</p>
			<button type="button" class="btn btn-lg" @click="openCardPage">Get started</button>
		</div>
	</div>
</template>
<script>
export default {
	props: ['plan','filpCard'],
	methods: {
        openCardPage() {
			this.$router.push({ path: 'card-page' })
		}
    }
}
</script>
<style lang='scss' scoped>

	.price-card {
		max-width: 384px;
		text-align: end;
		position: relative;
		background: #FFFFFF;
		box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
		border-top-right-radius:150px ;
		padding: 104px 70px 64px 38px;
		@media (min-width: 769px) and (max-width: 1400px) {
			max-width: 350px;
			padding: 72px 60px 46px 25px;
		}
		@media (max-width: 769px) {
			padding: 43px 19px 18px 14px;
			border-top-right-radius: 50px;

		}

		.price-period {
			font-weight: 600;
			font-size: 24px;
			line-height: 32px;
			text-align: right;
			color: #1E2D34;
			@media (min-width: 769px) and (max-width: 1400px) {

			}
			@media (max-width: 769px) {
				font-size: 16px;
				line-height: 13px;
			}
		}
		.price-count {
			font-weight: 600;
			font-size: 32px;
			line-height: 44px;
			text-align: right;
			color: #F89715;
			margin-bottom: 8px;
			@media (min-width: 769px) and (max-width: 1400px) {
				  font-size: 30px;
			}
			@media (max-width: 769px) {
				font-size: 13.0833px;
				line-height: 18px;
			}
			span.price {
				font-size: 56px;
				line-height: 76px;
				@media (min-width: 769px) and (max-width: 1400px) {
					font-size: 49px;
				}
				@media (max-width: 769px) {
					font-size: 22.8958px;
					line-height: 31px;
				}
			}
			span.dolar-sign {
				font-size: 40px;
				line-height: 54px;
				@media (min-width: 769px) and (max-width: 1400px) {

				}
				@media (max-width: 769px) {
					font-size: 16.3542px;
					line-height: 22px;

				}
			}
		}
		p {
			max-width: 91%;
    	margin-left: auto;
			font-size: 18px;
			line-height: 26px;
			margin-bottom: 48px;
			@media (min-width: 769px) and (max-width: 1400px) {
				font-size: 17px;
			}
			@media (max-width: 769px) {
				max-width: 100%;
				font-size: 12px;
				line-height: 16px;
				margin-bottom: 25px;
			}
		}
		button {
			width: 255px;
			color: #fff !important;
			background: linear-gradient(90deg, #FF7700 0%, #F49624 100%);
			border-radius: 12px;
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
			font-family: 'Poppins';
			font-style: normal;
			font-size: 14px;
			line-height: 21px;
			/* identical to box height */

			text-align: center;
			text-transform: uppercase;

			color: #363636;
			@media (min-width: 769px) and (max-width: 1400px) {
				width: 249px;

			}
			@media (max-width: 769px) {
				font-size: 9.8125px;
				line-height: 15px;
				width: 100%;
				margin-bottom: 8px;
			}
		}
	}
	.price-card.active {
		color: #FFFFFF;
		background: linear-gradient(90deg, #FF7700 0%, #F49624 100%);
	}
	.price-card.active .price-count,
	.price-card.active .price-period,
	.price-card.active p {
		color: #FFFFFF;
	}
	.price-card.active button {
		background: #fff;
		color: #363636 !important;
	}
	.price-tag {
		height: 0;
		border-bottom: 16px solid #fff;
		border-top: 16px solid #fff;
		border-right: 15px solid transparent;
		position: absolute;
		left: 0px;
		top: 31px;
		font-weight: bold;
		font-size: 16px;
		line-height: 26px;
		display: flex;
		align-items: center;
		text-align: right;
		color: #363636;
		padding-left: 50px;
		padding-right: 34px;
		@media (min-width: 769px) and (max-width: 1400px) {

		}
		@media (max-width: 769px) {
			font-size: 6.58333px;
			line-height: 11px;
			top: 13px;
			font-size: 6.58333px;
			line-height: 6px;
			padding-left: 14px;
			padding-right: 5px;
			border-bottom: 10px solid #fff;
			border-top: 10px solid #fff;
			border-right: 9px solid transparent;
		}
	}
	.bookmarkRibbon {
		transform: scale(-1, 1);
		-moz-transform: scale(-1, 1);
		-webkit-transform: scale(-1, 1);
		-o-transform: scale(-1, 1);
		-ms-transform: scale(-1, 1);
		transform: scale(-1, 1);
		width: 190px;
		height: 0;
		border-bottom: 17px solid #fff;
		border-top: 18px solid #fff;
		border-left: 15px solid transparent;
		float: right;
		margin-bottom: 20px;
		font-size: 17px;
		font-weight:700;
		position: absolute;
		left: 0px;
		top: 24px;
		@media (max-width: 769px) {
			width: 100px;
			top: 13px;
			border-bottom: 10px solid #fff;
			border-top: 9px solid #fff;
			border-left: 9px solid transparent;
		}
	}
	.bookmarkRibbon span {
		display: inline-block;
		transform: scale(-1, 1);
		-moz-transform: scale(-1, 1);
		-webkit-transform: scale(-1, 1);
		-o-transform: scale(-1, 1);
		-ms-transform: scale(-1, 1);
		transform: scale(-1, 1);
		color: #000;
		position: relative;
		top: -14px;
		text-align: center;
		right: 14px;
			@media (max-width: 769px) {
				font-size: 6.58333px;
				line-height: 11px;
			}
	}
	.price-card.flip {
		@media (max-width: 769px) {
			border-top-right-radius: 110px;
			padding: 73px 19px 18px 14px;
			padding-bottom: 27px;
			margin-bottom: 33px;
			transform: scale(-1, 1);
			-moz-transform: scale(-1, 1);
			-webkit-transform: scale(-1, 1);
			-o-transform: scale(-1, 1);
			-ms-transform: scale(-1, 1);
			transform: scale(-1, 1);
		}
		.price-info {
			@media (max-width: 769px) {
				text-align: left;
				// display: flex;

				// flex-direction: row;
				// justify-content: space-between;
			}
		}
		.price-period {
			@media (max-width: 769px) {
				font-size: 22px;
				line-height: 28px;
				margin-right: 20px;
				display: inline-block;
				transform: scale(-1, 1);

				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);
				-ms-transform: scale(-1, 1);
				transform: scale(-1, 1);
			}
		}
		.price-count {
			@media (max-width: 769px) {
				font-size: 18px;
				line-height: 25px;
				display: inline-block;
				transform: scale(-1, 1);

				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);
				-ms-transform: scale(-1, 1);
				transform: scale(-1, 1);
			}
			span.price {
				@media (max-width: 769px) {
					font-size: 22px;
					line-height: 30px;
					transform: scale(-1, 1);
					-moz-transform: scale(-1, 1);
					-webkit-transform: scale(-1, 1);
					-o-transform: scale(-1, 1);
					-ms-transform: scale(-1, 1);
					transform: scale(-1, 1);
				}
			}
			span.dolar-sign {

				@media (max-width: 769px) {
					font-size: 22px;
					line-height: 30px;
					transform: scale(-1, 1);

					-moz-transform: scale(-1, 1);
					-webkit-transform: scale(-1, 1);
					-o-transform: scale(-1, 1);
					-ms-transform: scale(-1, 1);
					transform: scale(-1, 1);
				}
			}
		}
		p {
			@media (max-width: 769px) {
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 30px;
				max-width: unset;
				margin-left: auto !important;
				transform: scale(-1, 1);

				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);
				-ms-transform: scale(-1, 1);
				transform: scale(-1, 1);
			}
		}
		button {
			@media (max-width: 769px) {
				// max-width: 80%;
				// margin: auto;
				font-weight: 600;
				width: 261px;
				transform: scale(-1, 1);
				-moz-transform: scale(-1, 1);
				-webkit-transform: scale(-1, 1);
				-o-transform: scale(-1, 1);
				-ms-transform: scale(-1, 1);
				transform: scale(-1, 1);
				margin-left: 20px;
				margin-right: 20px;
			}
		}
		.price-tag {
			top: 24px;
			font-size: 20px;
		}
		.bookmarkRibbon {
			@media (max-width: 769px) {
				left: 0px;
				top: 24px;
				width: 233px;
				top: 13px;
				border-bottom: 15px solid #fff;
				border-top: 14px solid #fff;
				border-left: 9px solid transparent;
			}

		}
		.bookmarkRibbon span {
				@media (max-width: 769px) {
					top: -14px;
					right: 14px;
				}
		}
		
		
			
	}


</style>